import { CopyOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Slider,
  Tag,
  Timeline,
  Tooltip,
  Typography
} from "antd";
import { APP_NAME, PageTitle } from "../auth";
import { modelEstimation, modelTranslate } from "../services/ModelTranslation";
import "./../../node_modules/flag-icon-css/css/flag-icons.min.css";
import AzureTranslateLogo from "./../assets/images/Azure_Logo.svg";
import AzureOpenAILogo from "./../assets/images/OpenAI_Logo.svg";
import dollarIcon from "./../assets/images/dollar.svg";
import translatingIcon from "./../assets/images/translating.gif";

import {
  CaretRightOutlined,
  ClockCircleOutlined,
  GlobalOutlined,
  QuestionOutlined,
  SwapOutlined,
  WechatOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Images
import ic_text_translator from "../assets/icons/ic-text-translator.png";
import langIcon from "../assets/images/lang.svg";
import targetIcon from "../assets/images/target.svg";
import { COUNTRIES } from "../common/const";
import { switchMap } from "rxjs";
import { setProfile } from "../redux/actions/profileActions";
import { getProfile } from "../services/Profile";

function TextTranslator() {
  const { Title, Text } = Typography;
  const { Option, OptGroup } = Select;
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState("");
  const [targets, setTargets] = useState("en");
  const [sourceText, setSourceText] = useState("");
  const [targetText, setTargetText] = useState("");
  const [selectedModel, setSelectedModel] = useState("1");
  const COMMON_LANGS = ["", "en", "ja", "vi", "ko", "zh-Hant", "de", "fr"];
  const COMMON_LANGS_TARGET = ["en", "ja", "vi", "ko", "zh-Hant", "de", "fr"];
  const [translationState, setTranslationState] = useState(false);
  const [estimationPrice, setEstimationPrice] = useState();
  const [numsOfToken, setNumsOfToken] = useState();
  const [isShowConfig, setIsShowConfig] = useState(false);
  const [actualPrice, setActualPrice] = useState();
  const [originSelectedLang, setOriginSelectedLang] = useState();
  const [copyStt, setCopyStt] = useState("Copy to clipboard");
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `${PageTitle.Text} - ${APP_NAME}`;
  }, []);

  const sourceOptions = (key) => {
    const val = COUNTRIES.translation[key];
    return (
      <Option key={val.name} value={key}>
        <Col span={24}>
          {val.icon ? (
            <div className={val.icon} />
          ) : (
            <div className="flag-icon">
              <QuestionOutlined style={{ fontSize: 30, width: "100%" }} />
            </div>
          )}

          <div>
            <b>{val.name}</b> <br />
            <smaller>({val.nativeName})</smaller>
          </div>
        </Col>
      </Option>
    );
  };

  const targetOptions = (key) => {
    const val = COUNTRIES.translation[key];
    return (
      <Option key={val.name} value={key}>
        <Col span={8}>
          {val.icon ? (
            <div className={val.icon} />
          ) : (
            <div className="flag-icon">
              <GlobalOutlined style={{ fontSize: 40, width: "100%" }} />
            </div>
          )}

          <div>
            <b>{val.name}</b> <br />
            <smaller>({val.nativeName})</smaller>
          </div>
        </Col>
      </Option>
    );
  };

  const timelineList = [
    {
      title: (
        <Row>
          <Col span={11}>
            <Card
              bordered={false}
              type="inner"
              className="criclebox tablespace mb-24 no-shadow padding-body select-project"
              title={null}
              style={{
                background: "#f8f8f8",
                border: "1px solid #f9f9f9",
                margin: 0,
              }}
            >
              <Select
                style={{ width: "calc(100% + 20px)" }}
                placeholder={"Select model"}
                bordered={false}
                onChange={(val) => setSelectedModel(val)}
                value={selectedModel}
              >
                {[
                  {
                    id: "1",
                    title: "Basic",
                    image: AzureTranslateLogo,
                    small: "Azure AI Translator",
                  },
                  {
                    id: "3",
                    title: "Standard",
                    image: AzureOpenAILogo,
                    small: "OpenAI GPT3.5",
                  },
                  {
                    id: "2",
                    title: "Premium",
                    image: AzureOpenAILogo,
                    small: "OpenAI GPT4o",
                  },
                ].map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {<img src={item.image} width={30} />} {item.title} -{" "}
                      <small>{item.small}</small>
                    </Option>
                  );
                })}
              </Select>
            </Card>
          </Col>
          {/* {selectedModel !== "1" && (
            <Col span={2}>
              <Col span={24} style={{ textAlign: "center" }}>
                <Tooltip title={"Settings"}>
                  <Button
                    onClick={() => {
                      setIsShowConfig(!isShowConfig);
                    }}
                    type={"link"}
                  >
                    <DashboardOutlined style={{ fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Col>
            </Col>
          )} */}
        </Row>
      ),
      time: "",
      color: "green",
      visible: true,
    },
    {
      title: (
        <Row>
          <Col span={11}>
            <Card
              bordered={false}
              type="inner"
              className="criclebox tablespace mb-24 no-shadow padding-body select-project"
              title={null}
              style={{
                background: "#f8f8f8",
                border: "1px solid #f9f9f9",
                margin: 0,
              }}
            >
              <Slider
                defaultValue={1}
                min={0}
                max={2}
                step={0.01}
                tooltip={{
                  open: true,
                }}
              />
              <Select
                style={{ width: "calc(100% + 20px)" }}
                placeholder={"Select style"}
                bordered={false}
              >
                {[
                  {
                    id: "formal",
                    pre_prompt: "Respond in a formal style ",
                  },
                  {
                    id: "casual",
                    pre_prompt: "Respond in a casual style ",
                  },
                  {
                    id: "technical",
                    pre_prompt: "Respond in a technical style ",
                  },
                  {
                    id: "humorous",
                    pre_prompt: "Respond in a humorous style ",
                  },
                  {
                    id: "persuasive",
                    pre_prompt: "Respond in a persuasive style ",
                  },
                ].map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {<WechatOutlined />} {item.pre_prompt}{" "}
                    </Option>
                  );
                })}
              </Select>
              <Select
                style={{ width: "calc(100% + 20px)" }}
                placeholder={"Select domain"}
                bordered={false}
              >
                {[
                  {
                    id: "finance",
                    pre_prompt: "Respond in a finance domain",
                  },
                  {
                    id: "human resources",
                    pre_prompt: "Respond in a human resources domain",
                  },
                  {
                    id: "information technology",
                    pre_prompt: "Respond in an information technology domain",
                  },
                  {
                    id: "sales marketing",
                    pre_prompt: "Respond in a sales marketing domain",
                  },
                  {
                    id: "operations",
                    pre_prompt: "Respond in an operations domain",
                  },
                  {
                    id: "legal",
                    pre_prompt: "Respond in a legal domain",
                  },
                  {
                    id: "customer service",
                    pre_prompt: "Respond in a customer service domain",
                  },
                  {
                    id: "research development",
                    pre_prompt: "Respond in a research development domain",
                  },
                  {
                    id: "product management",
                    pre_prompt: "Respond in a product management domain",
                  },
                  {
                    id: "compliance risk management",
                    pre_prompt:
                      "Respond in a compliance risk management domain",
                  },
                  {
                    id: "corporate strategy",
                    pre_prompt: "Respond in a corporate strategy domain",
                  },
                  {
                    id: "public relations communications",
                    pre_prompt:
                      "Respond in a public relations communications domain",
                  },
                  {
                    id: "supply chain management",
                    pre_prompt: "Respond in a supply chain management domain",
                  },
                  {
                    id: "data analytics",
                    pre_prompt: "Respond in a data analytics domain",
                  },
                  {
                    id: "facilities management",
                    pre_prompt: "Respond in a facilities management domain",
                  },
                ].map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {<WechatOutlined />} {item.pre_prompt}{" "}
                    </Option>
                  );
                })}
              </Select>
            </Card>
          </Col>
        </Row>
      ),
      time: "",
      color: "green",
      visible: isShowConfig,
    },
    {
      title: selectedModel ? (
        <>
          <Card
            bordered={false}
            type="inner"
            className="criclebox tablespace mb-24 no-shadow padding-body"
            title={null}
            style={{ background: "#f8f8f8", border: "1px solid #f9f9f9" }}
          >
            <Row gutter={[20, 20]}>
              <Col span={11}>
                <label>
                  <img src={langIcon} alt={"Document Translation"} /> Original
                  Language{" "}
                </label>
                <Select
                  style={{ width: "100%" }}
                  onChange={(val) => {
                    setSource(val);
                  }}
                  value={source}
                  className="lang-option-fix"
                  showSearch
                  placeholder="Select source"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  size="large"
                  dropdownRender={(menu) => (
                    <div className="lang-option">{menu}</div>
                  )}
                >
                  <OptGroup label={"Common"}>
                    {COMMON_LANGS.map((lang) => {
                      return sourceOptions(lang);
                    })}
                  </OptGroup>
                  <OptGroup label={"Others"}>
                    {Object.keys(COUNTRIES.translation)
                      .filter((item) => !COMMON_LANGS.includes(item))
                      .map((key) => {
                        return sourceOptions(key);
                      })}
                  </OptGroup>
                </Select>
                <br />
                <br />
                <Input.TextArea
                  className=""
                  onChange={(val) => {
                    _handleOnChangeInput(val.target.value);
                  }}
                  allowClear
                  placeholder="Input text"
                  autoSize={{ minRows: 10 }}
                  maxLength={5000}
                  showCount
                  value={sourceText}
                />

                <Row gutter={8} style={{ marginTop: "4px" }}>
                  {numsOfToken && (
                    <Col>
                      {" "}
                      <Row gutter={6}>
                        <Col>
                          <Typography.Text level={5} className="">
                            Tokens
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Tag>{numsOfToken}</Tag>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {estimationPrice && (
                    <Col>
                      <Row gutter={6}>
                        <Col>
                          <Typography.Text level={5} className="">
                            Estimated cost
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Tag color="lime">
                            <span className="money">
                              <img
                                src={dollarIcon}
                                style={{
                                  width: 15,
                                }}
                              />
                              <span>{estimationPrice}</span>
                            </span>
                          </Tag>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span={2}>
                <Col span={24} style={{ paddingTop: 20, textAlign: "center" }}>
                  <Button
                    disabled={source === "" || targets === ""}
                    onClick={() => {
                      setSource(targets);
                      setTargets(source);
                    }}
                    type={"link"}
                  >
                    <SwapOutlined style={{ fontSize: 20 }} />
                  </Button>
                </Col>
                <Col span={24} style={{ paddingTop: 120, textAlign: "center" }}>
                  <Button
                    onClick={() => setTranslationState(!translationState)}
                    type={"link"}
                  >
                    {loading ? (
                      <img src={translatingIcon} style={{ width: 25 }} />
                    ) : (
                      <CaretRightOutlined style={{ fontSize: 30 }} />
                    )}
                  </Button>
                </Col>
              </Col>
              <Col span={11}>
                <label>
                  <img
                    src={targetIcon}
                    width={20}
                    alt={"Document Translation"}
                  />{" "}
                  Target Languages{" "}
                </label>
                <Select
                  style={{ width: "100%" }}
                  onChange={(val) => setTargets(val)}
                  value={targets}
                  className="lang-option-fix"
                  showSearch
                  placeholder="Select targets"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  size="large"
                  dropdownRender={(menu) => (
                    <div className="lang-option">{menu}</div>
                  )}
                  maxTagCount={3}
                >
                  <OptGroup label={"Common"}>
                    {COMMON_LANGS_TARGET.map((lang) => {
                      return targetOptions(lang);
                    })}
                  </OptGroup>
                  <OptGroup label={"Others"}>
                    {Object.keys(COUNTRIES.translation)
                      .filter((item) => !COMMON_LANGS_TARGET.includes(item))
                      .map((key) => {
                        return targetOptions(key);
                      })}
                  </OptGroup>
                </Select>
                <br />
                <br />
                <Row>
                  <Col span={24} style={{ position: "relative" }}>
                    <Input.TextArea
                      readOnly
                      className="text-translator-result !pb-0"
                      onClick={(event) => {
                        event.target.select();
                      }}
                      value={targetText}
                      allowClear
                      placeholder="Translated text"
                      autoSize={{ minRows: 10 }}
                      style={{ cursor: "pointer" }}
                    />
                    {targetText && (
                      <Tooltip title={copyStt}>
                        <CopyOutlined
                          onClick={() => {
                            navigator.clipboard.writeText(targetText);
                            setCopyStt("Copied!");
                          }}
                          onMouseOut={() =>
                            setTimeout(() => {
                              setCopyStt("Copy to clipboard");
                            }, [2000])
                          }
                          title={copyStt}
                          style={{
                            position: "absolute",
                            bottom: 10,
                            right: 10,
                            fontSize: "18px",
                            cursor: "pointer",
                            color: "#1890ff",
                            zIndex: 30,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Col>
                  {actualPrice && (
                    <Col span={24}>
                      <Row gutter={8} style={{ marginTop: "4px" }}>
                        <Col>
                          <Typography.Text level={5} className="">
                            Actual cost
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Tag color="lime">
                            <span className="money">
                              <img
                                src={dollarIcon}
                                style={{
                                  width: 15,
                                }}
                              />
                              <span>{actualPrice}</span>
                            </span>
                          </Tag>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={9}></Col>
              <Col span={6}></Col>
            </Row>
          </Card>
        </>
      ) : (
        <span>
          You have no permission to translate file inside this project
        </span>
      ),
      time: "",
      color: "green",
      visible: true,
    },
  ];

  const _handleOnChangeInput = (value) => {
    setSourceText(value);
    if (!value || value === "") {
      setEstimationPrice(null);
      setActualPrice(null);
      setNumsOfToken(null);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      // You can use any AJAX library you like
      if (selectedModel) {
        if (sourceText === "") {
          setTargetText("");
        } else {
          const subscription_ = modelEstimation(
            selectedModel,
            source,
            targets,
            sourceText,
            1
          ).subscribe((res) => {
            if (res) {
              setEstimationPrice(res.estimationPrice);
              setNumsOfToken(res.numsOfToken);
              return () => {
                subscription_.unsubscribe();
              };
            }
          });
        }
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [sourceText, selectedModel, source, targets]);

  useEffect(() => {
    // You can use any AJAX library you like
    if (selectedModel) {
      if (sourceText === "") {
        setTargetText("");
      } else {
        setLoading(true);
        const subscription = modelTranslate(
          selectedModel,
          source,
          targets,
          sourceText,
          1,
          estimationPrice
        ).pipe(
            switchMap((res) => {
              if (res) {
                console.log(res)
                setTargetText(res.text);
                setCopyStt("Copy to clipboard");
                setActualPrice(res.actualPrice);
                setSource(res.fromLanguage);
                setLoading(false);
              }
              setLoading(false);
              return getProfile();
            })
        ).subscribe(
          (profileRes) => {
            if (profileRes.uuid) {
              dispatch(setProfile(profileRes));
            } else {
              alert("Something went wrong!");
            }
          },
        );

        return () => {
          subscription.unsubscribe();
        };

        // const subscription = modelTranslate(
        //   selectedModel,
        //   source,
        //   targets,
        //   sourceText,
        //   1,
        //   estimationPrice
        // ).subscribe((res) => {
        //   if (res) {
        //     setTargetText(res.text);
        //     setCopyStt("Copy to clipboard");
        //     setActualPrice(res.actualPrice);
        //     setSource(res.fromLanguage);
        //     setLoading(false);
        //     return () => {
        //       subscription.unsubscribe();
        //     };
        //   }
        //   setLoading(false);
        // });
      }
    }
  }, [translationState]);

  return (
    <>
      <Card
        className="tabled"
        title={
          <>
            {<img src={ic_text_translator} width={30} />}{" "}
            <b>{PageTitle.Text}</b>
          </>
        }
      >
        <Timeline className="timelinelist">
          {timelineList.map((t, index) => {
            if (t.visible) {
              if (t.dot) {
                return (
                  <Timeline.Item color={t.color} key={index} dot={t.dot}>
                    <Title level={5}>{t.title}</Title>
                    <Text>{t.time}</Text>
                  </Timeline.Item>
                );
              }
              if (selectedModel || index === 0) {
                return (
                  <Timeline.Item color={t.color} key={index}>
                    <Title level={5}>{t.title}</Title>
                    <Text>{t.time}</Text>
                  </Timeline.Item>
                );
              }
              return (
                <Timeline.Item
                  color={"red"}
                  key={index}
                  dot={<ClockCircleOutlined className="timeline-clock-icon" />}
                >
                  <Title level={5}>{"..."}</Title>
                </Timeline.Item>
              );
            }
          })}
        </Timeline>
      </Card>
    </>
  );
}

export default TextTranslator;
